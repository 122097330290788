import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import Layout from "../components/Layout/Layout";
import Hero from '../components/Hero/Hero';
import Seo from "../components/Seo/Seo";
import { BlockA, BlockC1, BlockC2, BlockQuote, BlockB, BlockD, BlockHeader, BlockBCarousel, BlockBCarouselCopy, BlockVR, BlockVideo } from '../components/BlockModule/BlockModule';
import { setNavColor, setNavDefaultColor, setNavTrigger, setCurrentPage } from "../actions";
import useWindowSize from "../utils/useWindowSize";
import HeroImage from './assets/The Home_image 1@2x.jpg';
import HeroImageBlur from './assets/The Home_image 1@2x_blur.jpg';
import HeroMobileImage from './assets/mobile/The Home_image 1@2x.jpg';
import HeroMobileImageBlur from './assets/mobile/The Home_image 1_blur@2x.jpg';
import ImageOne from './assets/The Home_image 2@2x.jpg';
import ImageTwo from './assets/The Home_image 3@2x.jpg';
import ImageTwoMobile from './assets/mobile/The Home_image 3@2x.jpg';
import CarouselOneImageOne from './assets/The Home_Image 4_carousel 1@2x.jpg';
import CarouselOneImageTwo from './assets/The Home_Image 4_carousel 2@2x.jpg';
import CarouselOneImageThree from './assets/The Home_Image 4_carousel 3@2x.jpg';
import CarouselTwoImageOne from './assets/The Home_Image 5_carousel 1@2x.jpg';
import CarouselTwoImageTwo from './assets/The Home_Image 5_carousel 2@2x.jpg';
import CarouselTwoImageThree from './assets/The Home_Image 5_carousel 3@2x.jpg';
import CarouselTwoImageFour from './assets/The Home_Image 5_carousel 4@2x.jpg';
import CarouselTwoImageFive from './assets/The Home_Image 5_carousel 5@2x.jpg';
import CarouselTwoImageSix from './assets/The Home_Image 5_carousel 6@2x.jpg';
import CarouselTwoImageSeven from './assets/The Home_Image 5_carousel 7@2x.jpg';
import CarouselTwoImageEight from './assets/The Home_Image 5_carousel 8@2x.jpg';
import CarouselThreeImageOne from './assets/The Home_Image 6_carousel 1@2x.jpg';
import CarouselThreeImageTwo from './assets/The Home_Image 6_carousel 2@2x.jpg';
import CarouselThreeImageThree from './assets/The Home_Image 6_carousel 3@2x.jpg';
import CarouselFourImageOne from './assets/The Home_Image 7_carousel 1@2x.jpg';
import CarouselFourImageTwo from './assets/The Home_Image 7_carousel 2@2x.jpg';
import CarouselFourImageThree from './assets/The Home_Image 7_carousel 3@2x.jpg';
import CarouselFourImageFour from './assets/The Home_Image 7_carousel 4@2x.jpg';
import VillaCarouselOne from './assets/mobile/carousel/The Home_Image 4_carousel 1@2x.jpg';
import VillaCarouselTwo from './assets/mobile/carousel/The Home_Image 4_carousel 2@2x.jpg';
import VillaCarouselThree from './assets/mobile/carousel/The Home_Image 4_carousel 3@2x.jpg';
import VillaCarouselFour from './assets/mobile/carousel/The Home_Image 4_carousel 4@2x.jpg';
import VillaCarouselFive from './assets/mobile/carousel/The Home_Image 4_carousel 5@2x.jpg';
import VillaCarouselSix from './assets/mobile/carousel/The Home_Image 4_carousel 6@2x.jpg';
import VillaCarouselSeven from './assets/mobile/carousel/The Home_Image 4_carousel 7@2x.jpg';
import VillaCarouselEight from './assets/mobile/carousel/The Home_Image 4_carousel 8@2x.jpg';
import VillaCarouselNine from './assets/mobile/carousel/The Home_Image 4_carousel 9@2x.jpg';
import TownhouseCarouselOne from './assets/mobile/carousel/The Home_Image 5_carousel 1@2x.jpg';
import TownhouseCarouselTwo from './assets/mobile/carousel/The Home_Image 5_carousel 2@2x.jpg';
import TownhouseCarouselThree from './assets/mobile/carousel/The Home_Image 5_carousel 3@2x.jpg';
import TownhouseCarouselFour from './assets/mobile/carousel/The Home_Image 5_carousel 4@2x.jpg';
import TownhouseCarouselFive from './assets/mobile/carousel/The Home_Image 5_carousel 5@2x.jpg';
import VillaPDF from './assets/mobile/Floorplan_for download_Villa.pdf';
import TownhousePDF from './assets/mobile/Floorplan_for download_Townhouse.pdf';
// markup
const HomePage = ({data}) => {
  const dispatch = useDispatch();
  const navTriggerRef = useRef();
  const [ Trigger, setTrigger] = useState();
  const [ width, height] = useWindowSize();

  const document = data.allPrismicHome.edges[0].node.data;
  const BlockAData = document.body[0].primary;
  const BlockQuoteData = document.body[1].primary.quote;
  const BlockBData = document.body[2].primary;
  const BlockVideoData = document.body[3] ? document.body[3].primary : null;

  console.log(BlockVideoData);

  const VillasDesktopCarouselOne = document.villas_desktop_carousel_one.map((item) => {
    return {
      image: {
        src: item.image.url,
        placeholder: item.image.url+'&w=100&blur=10&q=50',
        alt: item.image.alt,
        width: item.image.dimensions.width,
        height: item.image.dimensions.height
      }
    }
  });

  const VillasDesktopCarouselTwo = document.villas_desktop_carousel_two.map((item) => {
    return {
      caption: item.caption,
      image: {
        src: item.image.url,
        placeholder: item.image.url+'&w=100&blur=10&q=50',
        alt: item.image.alt,
        width: item.image.dimensions.width,
        height: item.image.dimensions.height
      }
    }
  });

  const VillasMobileCarousel = document.villas_mobile_carousel.map((item) => {
    return {
      caption: item.caption,
      image: {
        src: item.image.url,
        placeholder: item.image.url+'&w=100&blur=10&q=50',
        alt: item.image.alt,
        width: item.image.dimensions.width,
        height: item.image.dimensions.height
      }
    }
  });

  const TownhousesDesktopCarouselOne = document.townhouses_desktop_carousel_one.map((item) => {
    return {
      caption: item.caption,
      image: {
        src: item.image.url,
        placeholder: item.image.url+'&w=100&blur=10&q=50',
        alt: item.image.alt,
        width: item.image.dimensions.width,
        height: item.image.dimensions.height
      }
    }
  });

  const TownhousesDesktopCarouselTwo = document.townhouses_desktop_carousel_two.map((item) => {
    return {
      caption: item.caption,
      image: {
        src: item.image.url,
        placeholder: item.image.url+'&w=100&blur=10&q=50',
        alt: item.image.alt,
        width: item.image.dimensions.width,
        height: item.image.dimensions.height
      }
    }
  });

  const TownhousesMobileCarousel = document.townhouses_mobile_carousel.map((item) => {
    return {
      caption: item.caption,
      image: {
        src: item.image.url,
        placeholder: item.image.url+'&w=100&blur=10&q=50',
        alt: item.image.alt,
        width: item.image.dimensions.width,
        height: item.image.dimensions.height
      }
    }
  });


  useEffect(() => {
    dispatch(setCurrentPage('/home'));
    setTrigger(navTriggerRef.current);
  },[]);

  return (
    <Layout trigger={Trigger} defaultColor='white' footer>
      <Seo title="Home"/>
     <Hero ref={navTriggerRef} desktop={{src: document.hero_image[0].image.url, placeholder: document.hero_image[0].image.url+'&w=100&blur=10&q=50'}} 
        mobile={{src: document.hero_image[1].image.url, placeholder: document.hero_image[1].image.url+'&w=100&blur=10&q=50'}} small/>
      <BlockA animated image={{src: BlockAData.image.url, placeholder: BlockAData.image.url+'&w=100&blur=10&q=50' , alt: BlockAData.image.alt, width: BlockAData.image.dimensions.width, height: BlockAData.image.dimensions.height}} title={BlockAData.title} html={BlockAData.copy.html}/>
      { width > 900 ?
        <>
          <BlockQuote animated medium>{BlockQuoteData}</BlockQuote>
          <BlockB animated image={{src: BlockBData.image.url, placeholder: BlockBData.image.url+'&w=100&blur=10&q=50' , alt: BlockBData.image.alt, width: BlockBData.image.dimensions.width, height: BlockBData.image.dimensions.height}} />
        </>
      :
        <>
          <BlockB animated image={{src: BlockBData.image_mobile.url, placeholder: BlockBData.image_mobile.url+'&w=100&blur=10&q=50' , alt: BlockBData.image_mobile.alt, width: BlockBData.image_mobile.dimensions.width, height: BlockBData.image_mobile.dimensions.height}} />
          <BlockQuote animated medium>{BlockQuoteData}</BlockQuote>
        </>
      }
      
      { width > 900 ?
      <><BlockHeader animated>{document.villas_title}</BlockHeader>
      <BlockD animated none large={false} image={VillasDesktopCarouselOne} 
      details={document.villas_details} 
      callToAction={{label:document.villas_call_to_action_label, href:document.villas_call_to_action_link.url}} 
      html={document.villas_desktop_copy.html} reverse/>
      <BlockBCarousel animated data={VillasDesktopCarouselTwo} pagination autoplay={{
          delay: 5000,
          disableOnInteraction: true
        }}/></> : <BlockBCarouselCopy animated details={document.villas_details} 
        title={document.villas_title}
        callToAction={{label:document.villas_call_to_action_label, href:document.villas_call_to_action_link.url}} 
        html={document.villas_mobile_copy.html}
        image={VillasMobileCarousel}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true
        }}
        />}

        { /**BlockVRData && BlockVRData['the_viewer_link'].url ? <BlockVR 
        animated
      copy={BlockVRData.text}
      src={BlockVRData['the_viewer_link'].url}/> : null */}

      { BlockVideoData && BlockVideoData['video'].url ? <BlockVideo
        animated
      copy={BlockVideoData.text}
      src={BlockVideoData['video'].url}/> : null }
        
      {/** 
        { width > 900 ?
        <>
        <BlockHeader animated>{document.townhouses_title}</BlockHeader>
        <BlockD animated none large={false} image={TownhousesDesktopCarouselOne} 
      details={document.townhouses_details} 
      callToAction={{label:document.townhouses_call_to_action_label, href:document.townhouses_call_to_action_link.url}} 
      html={document.townhouses_desktop_copy.html}/>
      <BlockBCarousel animated data={TownhousesDesktopCarouselTwo} pagination autoplay={{
          delay: 5000,
          disableOnInteraction: true
        }}/>
      </> :
      <BlockBCarouselCopy animated details={document.townhouses_details} 
        title={document.townhouses_title}
        callToAction={{label:document.townhouses_call_to_action_label, href:document.townhouses_call_to_action_link.url}}  
        html={document.townhouses_mobile_copy.html}
        image={TownhousesMobileCarousel}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true
        }}/>
      }
    */}
    </Layout>
  )
}

export const query = graphql`
query HomeQuery {
  allPrismicHome {
    edges {
      node {
        data {
          hero_image {
            breakpoint
            image {
              url
              dimensions {
                height
                width
              }
            }
          }
          body {
            ... on PrismicHomeBodyBlockA {
              id
              primary {
                title
                copy {
                  html
                }
                image {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
            ... on PrismicHomeBodyBlockB {
              id
              primary {
                image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
                image_mobile {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
            ... on PrismicHomeBodyBlockQuote {
              id
              primary {
                quote: copy
              }
            }
            ... on PrismicHomeBodyBlockVr {
              id
              primary {
                text:copy
                the_viewer_link {
                  url
                }
              }
            }
            ... on PrismicHomeBodyBlockVideo {
              id
              primary {
                text:copy
                video {
                  url
                }
              }
            }
          }
          villas_title
          villas_call_to_action_label
          villas_call_to_action_link {
            url
          }
          villas_details {
            property
            value
          }
          villas_desktop_copy {
            html
          }
          villas_desktop_carousel_one {
            caption
            image {
              alt
              dimensions {
                height
                width
              }
              url
            }
          }
          villas_desktop_carousel_two {
            caption
            image {
              alt
              dimensions {
                height
                width
              }
              url
            }
          }
          townhouses_title
          townhouses_call_to_action_label
          townhouses_call_to_action_link {
            url
          }
          townhouses_desktop_carousel_one {
            caption
            image {
              alt
              url
              dimensions {
                height
                width
              }
            }
          }
          townhouses_desktop_carousel_two {
            caption
            image {
              alt
              url
              dimensions {
                height
                width
              }
            }
          }
          villas_mobile_carousel {
            caption
            image {
              alt
              url
              dimensions {
                height
                width
              }
            }
          }
          villas_mobile_copy {
            html
          }
          townhouses_desktop_copy {
            html
          }
          townhouses_details {
            property
            value
          }
          townhouses_mobile_carousel {
            caption
            image {
              alt
              dimensions {
                height
                width
              }
              url
            }
          }
          townhouses_mobile_copy {
            html
          }
        }
      }
    }
  }
}
`

export default HomePage